import React from 'react';
import img1 from '../assets/nearby/img1.1.webp';
import img2 from '../assets/nearby/img2.webp';
import img3 from '../assets/nearby/img3.png';
import img4 from '../assets/nearby/img4.webp';
import img5 from '../assets/nearby/img5.webp';
import img6 from '../assets/nearby/img6.webp';
import img7 from '../assets/nearby/img7.webp';
import img8 from '../assets/nearby/img8.webp';
import img9 from '../assets/nearby/img9.webp';
import img10 from '../assets/nearby/img10.webp';
import img11 from '../assets/nearby/img11.webp';
import img12 from '../assets/nearby/img12.webp';
import bg from "../assets/bg/banner-home.jpg";
import { Helmet } from 'react-helmet';
function PointOfInterest() {
  return (
    <div style={{ 
      backgroundImage: `url(${bg})`, 
      backgroundAttachment: 'fixed', 
      backgroundRepeat: 'no-repeat', 
      backgroundSize: 'cover', 
      backgroundPosition: 'center',
    }}>
        <Helmet>
        <title> Must-See Attractions and Points of Interest in Mukteshwar</title>
        <meta
          name="description"
          content=" Here we have mentioned the must visit places in Mukteshwar Nainital. Find out what to see and do near our Neverland Resorts in Mukteshwar Nainital"
        />
      </Helmet>
      <div className='bg-white bg-opacity-50'>
       <h1 className="font-semibold font-merriweather py-10 text-xl md:text-3xl xl:text-4xl justify-center items-center text-center gap-2">
      POINT OF INTEREST
      </h1>
      <section
        // style={{ backgroundImage: `url(${bg})` }}
        className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 place-content-center mx-auto gap-5 md:gap-14 p-3 md:py-10 bg-center bg-cover"
      >
        {[
          { img: img1, name: "Forest Trekking", distance: "0 KM" },
          { img: img2, name: "Bhalu Gaad Waterfall", distance: "12 KM" },
          { img: img3, name: "Mahadev Temple", distance: "15 KM" },
          { img: img4, name: "Satkhol Ashram", distance: "26 KM" },
          { img: img6, name: "Bhimtal", distance: "31 KM" },
          { img: img5, name: "Paragliding Adventure", distance: "35 KM" },
          { img: img7, name: "Mall Road Nainital", distance: "46 KM" },
          { img: img8, name: "Almora", distance: "59 KM" },
          { img: img9, name: "Jageshwar Dham", distance: "65 KM" },
          { img: img10, name: "RaniKhet", distance: "80 KM" },
          { img: img11, name: "Binsar", distance: "80 KM" },
          { img: img12, name: "Kausani", distance: "108 KM" },
        ].map((site, index) => (
          <div
            key={index}
            className="w-fit md:w-80 rounded-tr-3xl rounded-bl-3xl border-2 md:hover:scale-110 duration-700 shadow-lg shadow-black border-yellow-600 text-[#363535] bg-[#F1D191] md:hover:shadow-xl md:hover:shadow-slate-400 m-auto"
          >
            <img
              className="rounded-tr-3xl rounded-bl-3xl w-screen md:w-80 h-64"
              src={site.img}
              alt={site.name}
            />
            <div>
              <div className="flex flex-col justify-center items-center p-1">
                <h2 className="text-2xl font-glacial">{site.name}</h2>
                <p className="text-lg md:text-2xl font-times">{site.distance}</p>
              </div>
            </div>
          </div>
        ))}
      </section>   
      </div>
    
    </div>
  );
}

export default PointOfInterest;
