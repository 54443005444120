import React, { useState } from "react";
// import camera from "../assets/icons/camera_icon.png";
import location from "../assets/icons/location_icon.png";
import highways from "../assets/icons/highway_icon.png";
import airports from "../assets/icons/airport_icon.png";
import railway from "../assets/icons/railway_icon.png";
import Map from "../assets/photos/RouteMap2.png"

const NearBy2 = () => {
  const [activeSection, setActiveSection] = useState(0); // Initially set the first section to be active

  const toggleSection = (index) => {
    setActiveSection(activeSection === index ? -1 : index); // Toggle active section
  };

  const sectionData = [
    // {
    //   title: "Nearby Attractions",
    //   icon: camera,
    //   content: [
    //     { name: "Mukteshwar Mahadev Temple", distance: "13 km" },
    //     { name: "Bhalugaad Waterfall", distance: "13 km" },
    //     { name: "Chauli Ki Jali", distance: "15 km" },
    //     { name: "Satkhol Ashram ", distance: " 26 km" },
    //     { name: "Bhimtal Lake", distance: "32 km" },
    //     { name: "Kainchi Dham", distance: "42 km" },
    //     { name: "Mall ROAD Nainital", distance: "47 km" },
    //     { name: "Jageshwar Dham Temple", distance: "65 km" },
    //     { name: "Corbett National Park", distance: "95 km" },
    //   ],
    // },
    {
      title: "Key Distances",
      icon: location,
      content: [
        { name: "Ramgarh", distance: "33 km" },
        { name: "Almora", distance: "57 km" },
        { name: "Haldwani", distance: "58 km " },
        { name: "Ranikhet", distance: "80 km " },
        { name: "Binsar", distance: "80 km" },
        { name: "Kausani", distance: "108 km" },
      ],
    },
    {
      title: "Highways",
      icon: highways,
      content: [
        { name: "Dhanachuli State Highway", distance: "3.4 km" },
        { name: "NH 87 (Bhowali)", distance: "38 km" },
        { name: "NH 109 (Ranikhet)", distance: "81 km" },
      ],
    },
    {
      title: "Railways",
      icon: railway,
      content: [
        { name: "Kathgodam railway Station", distance: "51 km" },
        { name: "Haldwani Railway Station", distance: "57 km" },
      ],
    },
    {
      title: "Airports",
      icon: airports,
      content: [
        { name: "Pantnagar Airport (PGH)", distance: "85 km" },
      ],
    },
  ];


  // bg-[#9CA898] bg-opacity-40
  return (
    <div className=" m-3 md:m-10 mb-0 md:mb-0 rounded-2xl">
      <div className="contact_map row-fluid no-padd margin-lg-115t margin-sm-50t no-padd relative box-border flex flex-col">
        <h2 className="w-fit mx-auto text-xl md:text-2xl xl:text-4xl pt-5 tracking-widest xl:tracking-normal font-semibold font-merriweather text-center">
          Perfect Location for Your Retreat
        </h2>
        
        <div className="flex flex-col xl:flex-row justify-around items-center p-3 md:p-10  text-black">
          <div className="google-maps flex flex-col gap-5 w-full md:w-2/3 xl:w-1/4 ">
            <h3 className="font-merriweather text-xl md:text-2xl xl:text-3xl text-center text-[#2D3A1B] gap-2 md:gap-5">
              Nearby Locations
            </h3>

            {sectionData.map((section, index) => (
              <div key={index} className="section-container w-fit flex flex-col gap-5 border-b border-gray-300 ">
                <button
                  className="section-title flex text-center items-center gap-2 md:gap-5 w-80 md:w-60 xl:w-80 text-lg md:text-xl xl:text-2xl"
                  onClick={() => toggleSection(index)}
                  aria-expanded={activeSection === index}
                >
                  <img
                    className="bg-white xl:bg-transparent"
                    title={section.title}
                    src={section.icon}
                    alt={section.title}
                    style={{
                      height: "50px",
                      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
                    }}
                  />
                  {section.title}
                  <span className="ml-auto">
                    {activeSection === index ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 transform rotate-0"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 15l7-7 7 7"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 transform rotate-0"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    ) }
                  </span>
                </button>
                {activeSection === index && (
                  <div className="section-content">
                    {section.content.map((item, idx) => (
                      <ol key={idx} className="text-sm md:text-base flex justify-between w-80 md:w-96">
                        <li>{item.name}</li>
                        <li>{item.distance}</li>
                      </ol>
                    ))}
                  </div>
                )}
              </div>
            ))}

          </div>
          <div className="w-full  xl:w-1/2 flex flex-col gap-5 bg-[#DFE6E0] bg-opacity-70 xl:bg-opacity-0">
        <img src={Map} alt="Route Map of neverland resort" />
      </div>
        
        </div>
 

      </div>
    </div>
  );
};

export default NearBy2;
