import React from "react";
import wifi from "../assets/amenities-icons/wifi.png";
import cctv from '../assets/amenities-icons/CCTV Surveillance.png';
import commonparking from "../assets/amenities-icons/parking.png";
import dormatory from '../assets/amenities-icons/Dormitory for Drivers.png';
import wheelChair from '../assets/amenities-icons/wheelchair icon.png';
import electricCart from '../assets/amenities-icons/Electric Cart.png';
import recreational from '../assets/amenities-icons/Recreational Activities.png';
import roomService from '../assets/amenities-icons/Room Service.png';
import weddingPlanning from '../assets/amenities-icons/wedding-planning-icon.png'
import fineDine from '../assets/amenities-icons/Restaurant.png';
import poolSideBar from '../assets/amenities-icons/Poolside Bar.png';
import bonfire from '../assets/amenities-icons/Bonfire.png';
import swimmingPool from '../assets/amenities-icons/Swimming Pools.png';
import gym from "../assets/amenities-icons/gym.png";
import kids from "../assets/amenities-icons/Kids Playing Room.png";
import golfCourse from '../assets/amenities-icons/Golf Course.png';
import laundry from '../assets/amenities-icons/Laundry.png';
import valetParking from '../assets/amenities-icons/Valet Parking.png';
import conferenceRoom from '../assets/amenities-icons/Conference Rooms.png';
import barbeque from '../assets/amenities-icons/Barbeque.png';
import { Helmet } from "react-helmet";
const facilitiesList = [
  { imgSrc: wifi, altText: "High-Speed Wi-Fi Across the Resort", description: "High-Speed Wi-Fi Across the Resort" },
  { imgSrc: cctv, altText: "CCTV Surveillance", description: "CCTV Surveillance" },
  { imgSrc: commonparking, altText: "Common Parking Lot", description: "Common Parking Lot" },
  { imgSrc: dormatory, altText: "Dormitory for Drivers", description: "Dormitory for Drivers" },
  { imgSrc: wheelChair, altText: "Wheel Chair", description: "Wheel Chair" },

  { imgSrc: electricCart, altText: "Electric Cart for Your Convenience", description: "Electric Cart for Your Convenience" },
  { imgSrc: recreational, altText: "Recreational Activities", description: "Recreational Activities" },
  { imgSrc: roomService, altText: "Room Service", description: "Room Service" },
  { imgSrc: weddingPlanning, altText: "Wedding Planning Services", description: "Wedding Planning Services" },
  { imgSrc: fineDine, altText: "Fine Dining Restaurant", description: "Fine Dining Restaurant" },
  { imgSrc: poolSideBar, altText: "Poolside Bar & Grill", description: "Poolside Bar & Grill" },
  { imgSrc: gym, altText: "Wellness Retreat", description: "Wellness Retreat" },
  { imgSrc: bonfire, altText: "Bonfire & Musical Nights", description: "Bonfire & Musical Nights" },
  { imgSrc: swimmingPool, altText: "Swimming Pools", description: "Swimming Pools" },
  { imgSrc: kids, altText: "Kids Playing Room", description: "Kids Playing Room" },
  { imgSrc: golfCourse, altText: "Mini Golf Course", description: "Mini Golf Course" },
  { imgSrc: laundry, altText: "Laundry and Dry Cleaning Services", description: "Laundry and Dry Cleaning Services" },
  { imgSrc: valetParking, altText: "Valet Parking", description: "Valet Parking" },
  { imgSrc: conferenceRoom, altText: "Conference Rooms", description: "Conference Rooms" },
  { imgSrc: barbeque, altText: "Barbeque Gazebos", description: "Barbeque Gazebos" }
];

const Facilities = () => {
  return (
    <div className="flex flex-col gap-5 bg-cover bg-opacity-90 md:mx-10 xl:mx-20 rounded-2xl m-3">
      <Helmet>
        <title> NeverLand Resorts | Upcoming Resort In Mukteshwar</title>
        <meta
          name="description"
          content="NeverLand Resorts is an upcoming resort in Mukteshwar that features panoramic views, fine dining, and a peaceful ambience. In the middle of nature "
        />
      </Helmet>
      <h3 className="w-fit mx-auto text-xl md:text-2xl xl:text-4xl pt-5 tracking-widest xl:tracking-normal font-semibold font-merriweather text-center">
        Facilities and Amenities
      </h3>
      <div className="flex flex-col md:flex-row justify-center gap-3 md:gap-16 md:px-20">
        <ol className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-3 px-5 md:py-5 w-full text-base font-merriweather md:text-lg">
          {facilitiesList.map((facility, index) => (
            <li key={index} className="flex flex-col md:hover:scale-105 duration-500 items-center p-2 rounded-xl text-center gap-3">
              <img className="h-16" src={facility.imgSrc} alt={facility.altText} />
              {facility.description}
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default Facilities;
