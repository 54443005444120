import React, { useState, useEffect } from "react";
import bg from "../assets/bg/banner-home.jpg";
import img1 from "../assets/photos/pic1.jpg";
import img2 from "../assets/photos/pic2.jpg";
import img3 from "../assets/photos/pic3.jpg";
import img4 from "../assets/photos/pic4.jpg";
import img5 from "../assets/photos/pic5.jpg";
import img6 from "../assets/photos/pic6.jpg";
import { Helmet } from "react-helmet";
const images = [
  { src: img1, alt: "pic1" },
  { src: img2, alt: "pic2" },
  { src: img3, alt: "Gallery Image 3" },
  { src: img6, alt: "pic2" },

  { src: img4, alt: "Gallery Image 4" },
  { src: img5, alt: "Gallery Image 5" },
];

function Gallery() {
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openFullscreen = (image, index) => {
    setFullscreenImage(image);
    setCurrentIndex(index);
  };

  const closeFullscreen = () => {
    setFullscreenImage(null);
  };

  const showNextImage = () => {
    const nextIndex = (currentIndex + 1) % images.length;
    setFullscreenImage(images[nextIndex]);
    setCurrentIndex(nextIndex);
  };

  const showPreviousImage = () => {
    const prevIndex = (currentIndex - 1 + images.length) % images.length;
    setFullscreenImage(images[prevIndex]);
    setCurrentIndex(prevIndex);
  };

  const handleKeyDown = (event) => {
    if (fullscreenImage) {
      if (event.key === "ArrowRight") {
        showNextImage();
      } else if (event.key === "ArrowLeft") {
        showPreviousImage();
      } else if (event.key === "Escape") {
        closeFullscreen();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [fullscreenImage, currentIndex]);

  return (
    <div
      className="min-h-screen pb-20 flex flex-col justify-center items-center"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundAttachment: "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center top",
      }}
    >
       <Helmet>
        <title>Explore Stunning Gallery Our Hotel In Mukteshwar</title>
        <meta
          name="description"
          content="Gallery of our top resort in Mukteshwar Nainital. View breathtaking images of elegant rooms, lush surroundings, and exclusive amenities"
        />
      </Helmet>
      <div className="bg-white bg-opacity-50">
        <div className="flex flex-col justify-center items-center">
          <h1 className="font-semibold font-merriweather text-xl md:text-3xl xl:text-4xl justify-center items-center text-center gap-2 pt-10">
            Tentative layout of Resort
          </h1>
        </div>

        <section className="flex flex-col md:flex-row md:p-5 md:pb-0 lg:px-10 lg:pb-0">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-20 p-5 pb-0 w-full bg-cover mx-auto py-10 md:pb-20">
            {images.map((image, index) => (
              <img
                key={index}
                className="shadow-md border border-black p-1 shadow-amber-50 gap-5 md:hover:shadow-[#74613C] md:hover:shadow-lg lg:hover:scale-110 md:duration-700 cursor-pointer"
                src={image.src}
                alt={image.alt}
                onClick={() => openFullscreen(image, index)}
              />
            ))}
          </div>
        </section>

        {fullscreenImage && (
          <div
            className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-90 flex justify-center items-center"
            onClick={closeFullscreen}
          >
            <button
              className="absolute bottom-28 left-20 text-black bg-white font-semibold text-sm md:text-xl font-serif m-3 p-3 md:p-4 my-auto md:mr-8 md:px-8 rounded-xl hover:bg-green-300 hover:text-black hover:font-bold focus:outline-none"
              onClick={(e) => {
                e.stopPropagation();
                showPreviousImage();
              }}
            >
              Prev
            </button>
            <button
              className="absolute bottom-28 right-20 text-black bg-white font-semibold text-sm md:text-xl font-serif m-3 p-3 md:p-4 my-auto md:mr-8 md:px-8 rounded-xl hover:bg-green-300 hover:text-black hover:font-bold focus:outline-none"
              onClick={(e) => {
                e.stopPropagation();
                showNextImage();
              }}
            >
              Next
            </button>
            <img
              src={fullscreenImage.src}
              alt={fullscreenImage.alt}
              className="max-h-full max-w-full cursor-pointer md:p-20 md:pt-32"
              onClick={closeFullscreen}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Gallery;
