// Import necessary dependencies
import React from "react";
// import bgContact from "../assets/bg/contact_bg2.jpg";
// import ContactForm from "./ContactForm";
// import CompanyDetail from "./CompanyDetail";
import ContactDetails from "./ContactDetails";
import bg from "../assets/bg/banner-home.jpg";
import { Helmet } from "react-helmet";

// Define the functional component for the form
const Contact = () => {
  return (
    <div style={{ 
      backgroundImage: `url(${bg})`, 
      backgroundAttachment: 'fixed', 
      backgroundRepeat: 'no-repeat', 
      backgroundSize: 'cover', 
      backgroundPosition: 'center',
    }}>
     <Helmet>
        <title> Contact Us at Neverland Resorts: Best Resort Near Nainital</title>
        <meta
          name="description"
          content="Get in touch with Neverland Resorts, your luxurious resort in Mukteshwar. Contact us for bookings, inquiries, or more information "
        />
      </Helmet>
      {/* <CompanyDetail/> */}
      <div className="bg-cover flex flex-col justify-center items-center mx-auto bg-center md:p-10 bg-white bg-opacity-50" >
      {/* style={{ backgroundImage: `url(${bgContact})`}} */}
      <h2 className="animate__zoomIn text-2xl md:text-5xl text-center font-merriweather pt-5">
     Contact Us..
      </h2>
      {/* <p className="text-lg md:text-2xl text-white font-mono my-2 mx-5 text-center">
              We are here to help you get your Dream Land!
            </p> */}
        <ContactDetails/>
       
        {/* <ContactForm/> */}
      </div>      
    </div>
  );
};

// Export the component
export default Contact;
