import React, { useEffect, useRef } from "react";
import LocomotiveScroll from "locomotive-scroll";
import bg from "./assets/bg/banner-home.jpg";
import HomeContent from "./components/HomeContent";
import NearBy2 from "./components/NearBy2";
import Facilities from "./components/Facilities";
import { Helmet } from "react-helmet";

const Home = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const scroll = new LocomotiveScroll({
      el: containerRef.current,
      smooth: true,
    });

    return () => {
      scroll.destroy();
    };
  }, []);

  return (
    <div
      style={{
        backgroundImage: `url(${bg})`,
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Helmet>
        <title>NeverLand Resorts | Upcoming Resort In Mukteshwar</title>
        <meta
          name="description"
          content="NeverLand Resorts is an upcoming resort in Mukteshwar that features panoramic views, fine dining, and a peaceful ambience. In the middle of nature."
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "NeverLand Resorts",
            "alternateName": "NeverLand Resorts",
            "url": "https://www.neverlandresorts.com/",
            "logo": "https://www.neverlandresorts.com/static/media/N-land.19d996ccd59470471503.png",
          })}
        </script>
      </Helmet>
      <div className="bg-white bg-opacity-50">
        <HomeContent />
        <Facilities />
        <NearBy2 />
      </div>
    </div>
  );
};

export default Home;
