import React from 'react'
import pic from '../assets/photos/website  building NEVERLAND.png'

const HomeContent = () => {
  return (
    <div className=" flex flex-col gap-10 p-3 md:p-10 font-merriweather ">
    <div className=" md:px-10 ">
      <h1 className=" font-semibold text-xl md:text-3xl xl:text-4xl mb-5 md:mb-10 justify-center items-center text-center gap-2">
       Neverland Resorts : Coming Soon
      </h1>

      <p className="text-base md:text-lg xl:text-xl text-justify">
      Welcome to Neverland, your luxurious resort in Mukteshwar, set amidst the majestic Himalayan ranges. Just an hour’s drive from Nainital on the scenic Dhanachuli State Highway, our resort sits at 7,800 feet above sea level, offering a tranquil escape from bustling hill stations like Shimla and Mussoorie. Surrounded by pristine forests and breathtaking views, this hotel in Mukteshwar provides a serene sanctuary where you can immerse yourself in nature’s untouched beauty.
      </p>

<img className="p-3 md:p-5 xl:p-10 scale-90" src={pic} alt="Neverland resorts in mukteshwar" />

<p className="text-base md:text-lg xl:text-xl text-justify">
Enjoy panoramic views of snow-covered Himalayan peaks in winter and the rich wildlife and vibrant flora throughout the year. Our resort features a range of activities and experiences, from recreational games and bird-watching to wellness retreats and adventurous treks. Indulge in our room service or dine at our fine dining restaurant and poolside bar & grill. We offer a variety of recreational activities, wellness retreat, bonfire nights, swimming pool, and mini golf course. Our facilities also include conference rooms, and barbeque gazebos.
      </p>
      <p className="text-base md:text-lg xl:text-xl text-justify">
      For your peace of mind, our resort in Mukteshwar, Nainital is a secure retreat spot, featuring a gated and fenced perimeter, CCTV surveillance, guard dogs, and 24/7 security. Whether you’re here to relax or celebrate a special occasion, including weddings with our dedicated planning services, corporate events, and private gatherings, Neverland Resorts offers cottages for stay in Mukteshwar and ensures a memorable experience with its exceptional settings and dedicated service.
      </p>
    </div>
   

  </div>
  )
}

export default HomeContent
