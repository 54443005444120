import React from "react";
import bg from "../assets/bg/banner-home.jpg";
import img1 from "../assets/Events/FOREVER AFTER IN THE HILLS.png";
import img2 from "../assets/Events/CORPORATE RETREATS.png";
import img3 from "../assets/Events/MEMORABLE MOMENTS.png";
import img4 from "../assets/Events/WARM REUNIONS.png";
import { Helmet } from "react-helmet";
const PlanEvents = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${bg})`,
        backgroundAttachment: "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
    <Helmet>
        <title>Best Event Venue In Mukteshwar</title>
        <meta
          name="description"
          content="We have the best venue in Mukteshwar for wedding, reception parties, engagement, corporate events or meetings, birthday celebration and other get-togethers"
        />
      </Helmet>
      <div className=" flex flex-col gap-10 p-3 md:p-10 font-merriweather bg-white bg-opacity-50">
        <h1 className=" font-semibold text-xl md:text-3xl xl:text-4xl justify-center items-center text-center gap-2">
          PLAN AN EVENT
        </h1>
        <div className=" flex flex-col md:flex-row gap-5 md:gap-10 justify-center items-center">
          <div className=" flex flex-col gap-5 md:px-10">
            <p className=" font-semibold text-lg md:text-2xl xl:text-3xl justify-center items-center gap-2">
              Forever After In The Hills
            </p>
            <p className="text-sm md:text-base xl:text-lg text-justify">
              <span className="font-semibold"> Destination Weddings: </span>{" "}
              Exchange vows with the stunning Himalayan peaks as your backdrop,
              creating a magical and unforgettable wedding day at this perfect
              venue for wedding in Mukteshwar
            </p>
            <p className="text-sm md:text-base xl:text-lg text-justify">
              <span className="font-semibold"> Reception Parties: </span>{" "}
              Celebrate your special day with a lively reception featuring
              exquisite cuisine, dancing, and joyful moments with your loved
              ones, all amidst the stunning mountain scenery.
            </p>
            <p className="text-sm md:text-base xl:text-lg text-justify">
              <span className="font-semibold"> Engagement Parties: </span>{" "}
              Celebrate your engagement surrounded by the breathtaking beauty of
              the mountains, making the moment extra special with family and
              friends.
            </p>
            <p className="text-sm md:text-base xl:text-lg text-justify">
              <span className="font-semibold"> Anniversary Celebrations: </span>{" "}
              Mark years of love and commitment with a celebration that reflects
              your journey together, surrounded by the awe-inspiring beauty of
              the Himalayas.
            </p>
          </div>

          <img className="w-1/5" src={img1} alt="Wedding venue in mukteshwar" />
        </div>

        <div className=" flex flex-col md:flex-row-reverse gap-5 md:gap-10 justify-center items-center">
          <div className=" flex flex-col gap-5 md:px-10">
            <h2 className=" font-semibold text-lg md:text-2xl xl:text-3xl justify-center items-center gap-2">
              Corporate Retreats
            </h2>
            <p className="text-sm md:text-base xl:text-lg text-justify">
              <span className="font-semibold"> Conferences and Seminars: </span>{" "}
              Organize inspiring and productive meetings in our versatile
              spaces, offering everything you need for success while enjoying
              panoramic mountain views.
            </p>
            <p className="text-sm md:text-base xl:text-lg text-justify">
              <span className="font-semibold"> Team-Building Activities: </span>{" "}
              Foster team spirit and creativity with interactive sessions and
              activities in a tranquil mountain environment.
            </p>
          </div>
          <img className="w-1/5" src={img2} alt="Corporate event venue in mukteshwar" />
        </div>

        <div className=" flex flex-col md:flex-row gap-5 md:gap-10 justify-center items-center">
          <div className=" flex flex-col gap-5 md:px-10">
            <p className=" font-semibold text-lg md:text-2xl xl:text-3xl justify-center items-center gap-2">
              Memorable Moments
            </p>
            <p className="text-sm md:text-base xl:text-lg text-justify">
              <span className="font-semibold"> Family Functions: </span> Our
              venue in Mukteshwar offers the perfect scenic setting for creating
              lasting memories with family that brings generations together to
              celebrate and reconnect.
            </p>
            <p className="text-sm md:text-base xl:text-lg text-justify">
              <span className="font-semibold"> Birthday Parties: </span> Enjoy
              personalized birthday celebrations with themed decor and special
              touches, all set against the stunning backdrop of the mountains.
            </p>
          </div>
          <img className="w-1/5" src={img3} alt="Best event venue in mukteshwar" />
        </div>

        {/* <div className=" flex flex-col gap-5 md:px-10">
        <h2 className=" font-semibold text-lg md:text-2xl xl:text-3xl justify-center items-center gap-2">
        Sports & Fitness Events
        </h2>
        <p className="text-sm md:text-base xl:text-lg text-justify">
       <span className='font-semibold'> Family Reunions: </span>  Create cherished memories with family gatherings in a picturesque setting that brings together multiple generations to celebrate and reconnect.
        </p>
        <p className="text-sm md:text-base xl:text-lg text-justify">
       <span className='font-semibold'> Birthday Parties: </span>  Celebrate birthdays with personalized themes and special touches, surrounded by the breathtaking beauty of the mountains.
        </p>
        <p className="text-sm md:text-base xl:text-lg text-justify">
       <span className='font-semibold'> Anniversary Celebrations: </span> Toast to years of love and commitment with a celebration that honors your journey together, framed by the awe-inspiring Himalayan backdrop.
        </p>
      </div> */}

        {/* <div className=" flex flex-col gap-5 md:px-10">
        <h2 className=" font-semibold text-lg md:text-2xl xl:text-3xl justify-center items-center gap-2">
        Private Events
        </h2>
        <p className="text-sm md:text-base xl:text-lg text-justify">
       <span className='font-semibold'>Intimate Gatherings: </span>  Host small, personalized events for close friends or family in a secluded, serene setting that ensures privacy and intimacy.
        </p>
        <p className="text-sm md:text-base xl:text-lg text-justify">
       <span className='font-semibold'> Exclusive Parties: </span> Enjoy high-profile or private events with tailored services, set against the stunning backdrop of the Himalayas for a truly memorable experience.
        </p>
      
      </div> */}

        <div className=" flex flex-col md:flex-row-reverse gap-5 md:gap-10 justify-center items-center">
          <div className=" flex flex-col gap-5 md:px-10">
            <h2 className=" font-semibold text-lg md:text-2xl xl:text-3xl justify-center items-center gap-2">
              Warm Reunions
            </h2>
            <p className="text-sm md:text-base xl:text-lg text-justify">
              <span className="font-semibold">
                {" "}
                Vlogger and Influencer Meetups:{" "}
              </span>{" "}
              Attend or organize exclusive gatherings for vloggers and
              influencers, providing a unique platform for networking and
              collaboration amid breathtaking scenery at the best event venue
              that Mukteshwar has to offer.
            </p>
            <p className="text-sm md:text-base xl:text-lg text-justify">
              <span className="font-semibold">
                {" "}
                School and College Reunions:{" "}
              </span>{" "}
              Reconnect with classmates and celebrate memories in an environment
              that adds a touch of magic to your reunion.
            </p>
            <p className="text-sm md:text-base xl:text-lg text-justify">
              <span className="font-semibold"> Get-Togethers: </span> Arrange
              casual or themed gatherings for friends, family, or colleagues,
              enjoying the relaxed and scenic environment of Mukteshwar.
            </p>
          </div>
          <img className="w-1/5" src={img4} alt="Mukteshwar venue for small gatherings" />
        </div>
      </div>
    </div>
  );
};

export default PlanEvents;
