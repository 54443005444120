import React from "react";
// import location from "./icons2/location.gif";
// import TSGOC_LOGO_F_8 from "../assets/icons/TSGOC_LOGO_F_8.png"
import message from "../assets/icons/message.gif";
import phone from "../assets/icons/phone.gif";

// import icon_facebook from "../assets/icons/icon_facebook.png";
// import icon_twitter from "../assets/icons/icon_twitter.png";
// import icon_insta from "../assets/icons/icon_insta.png";
// import icon_linkdin from "../assets/icons/icon_linkdin.png";

function ContactDetails() {
  const phoneNumber = "8510850101";
  return (
    <section className="w-full flex flex-col md:flex-row md:m-3 p-5 md:backdrop:pt-14 rounded-xl md:gap-10 md:mx-40">
      <div className="flex w-full md:w-3/4 flex-col gap-6">
        <div className="flex flex-col items-center gap-3">
          <div className="flex flex-col gap-3 ">
            {/* <p className="text-2xl w-full md:text-3xl py-3 md:py-5 px-5 md:px-10 font-serif">
              Connect with us:
            </p> */}
            <div className="flex gap-3 md:gap-1 items-center pl-5 md:pl-10">
              <a href="mailto:info@theshubhamgoc.com">
                <img
                  className="h-11 rounded-lg animate__zoomIn2 hover:shadow-lg hover:shadow-green-100"
                  src={message}
                  alt="message"
                />
              </a>
              <a
                className="text-base xl:text-lg tracking-widest w-fit pl-0 md:p-3 font-semibold hover:text-blue-400 "
                href="mailto:info@theshubhamgroup.com"
              >
                {"info@theshubhamgroup.com "}
              </a>
            </div>

            <div className="flex gap-3 md:gap-1 items-center pl-5 md:pl-10">
              <a href={`tel:${phoneNumber}`} className="flex gap-4 md:gap-1 xl:gap-2">
                <img
                  className="h-10 rounded-lg animate__zoomIn2 hover:shadow-lg hover:shadow-green-100"
                  src={phone}
                  alt="message"
                />
                <p className="text-base font-semibold xl:text-lg  w-fit pl-0 md:p-3">
                  {" "}
                  8510 850 101
                </p>
              </a>
            </div>

            <iframe
              className="text-3xl md:text-4xl h-[300px] p-8  rounded-2xl font-semibold font-serif"
              id="Contact_Form"
              src="https://account.solidperformers.com/capture_form_data/MTI0Mw=="
              border="0"
              style={{ border: 0 }}
            ></iframe>
          </div>
        </div>
      </div>
      {/* Google Location */}
      <div className="google-maps w-full flex flex-col gap-5 md:px-0 md:py-20 xl:p-20 md:pt-0 xl:pt-0">
        {/* <h3 className="font-merriweather text-2xl md:text-4xl xl:text-5xl text-[#2D3A1B] text-center gap-2 md:gap-5">
              Google Location
            </h3> */}
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3521.722746342617!2d79.6754694!3d29.3959469!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39a0be222e899d25%3A0xdf2637974bc5556f!2sOkhalkanda%2C%20Uttarakhand%20263132!5e0!3m2!1sen!2sin!4v1599144302090!5m2!1sen!2sin"
          className="w-full h-60 md:h-[400px] border-0"
          allowFullScreen=""
          loading="lazy"
          title="Google Map of Poetry By The Hills"
          aria-label="Google Map of Poetry By The Hills"
        ></iframe>
      </div>
    </section>
  );
}

export default ContactDetails;
