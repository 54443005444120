import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import logo from './assets/logo/N-land.png'
// import bg from "../assets/bg/WebsiteBg3.avif";

// import whatsapp from "./assets/icons/whatsapp.png";
import call from "./assets/icons/call2.png";

function Header() {
  const [toggle, setToggle] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header className={`w-full z-40 sticky top-0`}>
        <div
          className={`bg-[#363535]
            // -[#FFFFF0]
             ${
            scrolling ? "bg-opacity-100" : "bg-opacity-100"
          }`}
        >
          <div className="w-full flex justify-between h-20 md:h-32 xl:h-[118px] items-center text-[#40392d]">
            <div className="flex flex-col justify-center items-center">
            <Link onClick={() => { setToggle(!toggle) }} to='/'>
                        {/* <img className='w-36 md:w-52 pb-1 md:px-4 rounded-full' src={TSGOC_LOGO_F_8} alt='logo' /> */}
                        <img className='h-10 md:h-20 ml-2 md:ml-5' src={logo} alt='logo' />

                    </Link>
            </div>
            {/* <div className="xl:hidden flex flex-col">
          
              <a
                class="flex gap-2 justify-center items-center text-xl font-semibold leading-6 text-[#AD9535] hover:text-[#CBA328]  rounded-3xl py-1 px-2"
                href="tel:8510850101"
              >
                <img className="w-7 " src={call} alt="logo" />
                Call Us 8510 850 101
              </a>
            </div> */}

            {toggle ? (
              <AiOutlineClose
                onClick={() => setToggle(!toggle)}
                className="xl:hidden block text-[#E2E2E4] text-2xl md:text-5xl md:font-bold mx-4"
                aria-label="Close Menu"
              />
            ) : (
              <AiOutlineMenu
                onClick={() => setToggle(!toggle)}
                className="xl:hidden block text-[#E2E2E4] text-2xl md:text-5xl md:font-bold mx-4"
                aria-label="Open Menu"
              />
            )}
            <div className="hidden xl:flex flex-row z-30 text-base font-[rating] px-4 pr-10 gap-10">
              <a
                className="md:hover:scale-110 duration-500 text-[#E2E2E4] hover:text-[#EFCF8F]"
                href="/"
              >
                HOME
              </a>   
              <a
                className="md:hover:scale-110 duration-500 text-[#E2E2E4] hover:text-[#EFCF8F] "
                href="/about-neverlandresorts"
              >
               ABOUT RESORT
              </a>  
              <a
                className="md:hover:scale-110 duration-500 text-[#E2E2E4] hover:text-[#EFCF8F] "
                href="/event-venue-in-mukteshwar"
              >
               PLAN EVENT
              </a> 
              <a
                className="md:hover:scale-110 duration-500 text-[#E2E2E4] hover:text-[#EFCF8F] "
                // href="/about"
              >
               FINE DINE
              </a>  
              <a
                className="md:hover:scale-110 duration-500 text-[#E2E2E4] hover:text-[#EFCF8F] "
                href="/amenities"
              >
               AMENITIES
              </a>  
              <a
                className="md:hover:scale-110 duration-500 text-[#E2E2E4] hover:text-[#EFCF8F] "
                href="/gallery"
              >
              GALLERY
              </a> 
              <a
                className="md:hover:scale-110 duration-500 text-[#E2E2E4] hover:text-[#EFCF8F] "
                href="/point-of-interest"
              >
                 POINT OF INTEREST
              </a>
             <a
                className="md:hover:scale-110 duration-500 text-[#E2E2E4] hover:text-[#EFCF8F] "
                href="/contact"
              >
               REACH US
              </a> 
              {/* <div className="flex mr-10 gap-5">
                <div className="flex flex-col gap-2">
             
                  <a
                    className="flex gap-2 justify-center items-center text-lg md:text-xl font-semibold leading-6 text-[#AD9535] md:hover:scale-110 duration-500 md:hover:text-[#E2E2E4] rounded-3xl px-2"
                    href="tel:8510850101"
                  >
                    <img className="w-7" src={call} alt="Call Us" />
                    Call Us 8510 850 101
                  </a>
                </div>
              </div> */}
            </div>
            <div
              className={`xl:hidden flex flex-col fixed bg-black text-2xl md:text-6xl md:p-20 font-french-canon text-[#E2E2E4] font-[rating] gap-10 md:gap-10 pt-5 pl-5 bg-opacity-50 top-20 md:top-32 w-full h-screen ${
                toggle ? "left-0" : "left-[-100%]"
              } duration-700`}
            >
              <a onClick={() => setToggle(false)} href="/">
                HOME
              </a>
               <a onClick={() => setToggle(false)} href="/about-neverlandresorts" >
                ABOUT RESORT
              </a> 
              <a onClick={() => setToggle(false)}  href="/event-venue-in-mukteshwar">
               PLAN EVENT
              </a>     
              <a onClick={() => setToggle(false)}>
               FINE DINE
              </a>     
              <a onClick={() => setToggle(false)} href="/amenities">
               AMENITIES
              </a>            
              <a onClick={() => setToggle(false)} href="/gallery">
              GALLERY
              </a>  
              <a onClick={() => setToggle(false)} href="/point-of-interest">
                POINT OF INTEREST
              </a>
             
              <a onClick={() => setToggle(false)} href="/contact">
                REACH US
              </a>
            </div>
          </div>
        </div>
      </header>

{/* 
      <BookingForm /> */}
      {/* <div
        style={{ position: "fixed", bottom: "20px", right: "20px", zIndex: 30 }}
      >
        <a
          href="https://wa.me/918744989989"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={whatsapp}
            alt="WhatsApp"
            style={{ width: "50px", height: "50px" }}
          />
        </a>
      </div> */}
    </>
  );
}

export default Header;
