import React from "react";
import pic from '../assets/photos/website  building NEVERLAND.png'
import bg from "../assets/bg/banner-home.jpg";
import img1 from '../assets/photos/weather.png'
import { Helmet } from "react-helmet";
const AboutUs = () => {
  return (
    <div style={{ 
      backgroundImage: `url(${bg})`, 
      backgroundAttachment: 'fixed', 
      backgroundRepeat: 'no-repeat', 
      backgroundSize: 'cover', 
      backgroundPosition: 'center',
    }}>
     <Helmet>
        <title> About Neverland Resorts - Mukteshwar's Premier Hotel</title>
        <meta
          name="description"
          content="About Neverland Resorts in Mukteshwar – our story, mission, and dedication to offering a luxurious and unforgettable hospitality experience"
        />
      </Helmet>
    <div className=" flex flex-col gap-10 p-3 md:p-10 font-merriweather bg-white bg-opacity-50">
      <div className=" md:px-10 ">
        <h1 className=" font-semibold text-xl md:text-3xl xl:text-4xl mb-5 md:mb-10 justify-center items-center text-center gap-2">
         Neverland Resorts
        </h1>

        <p className="text-base md:text-lg xl:text-xl text-justify">
          Neverland Resorts is a luxurious stay for the elites. This place
          resides in Mukteshwar, Uttarakhand, just a one-hour drive from
          Nainital. Our resort is 7,800 feet above sea level and situated on
          Dhanachuli State Highway. This resort is away from all the hustle and
          bustle of most hill stations like Nainital, Shimla, Mussoorie, and
          others. Enjoy the beauty of the Himalayas in peace and with stunning
          views.
        </p>

<img className="p-3 md:p-5 xl:p-10 scale-90" src={pic} alt="Apartment blocks in neverland resorts mukteshwar" />
      </div>
      <div className=" md:px-10 flex flex-col gap-5 ">
        <h3 className=" font-semibold text-xl md:text-3xl xl:text-4xl justify-center items-center text-center gap-2">
          Flora & Fauna
        </h3>

        <p className="text-base md:text-lg xl:text-xl text-justify">
          The resort is surrounded by the abundant and varied wildlife that
          Mukteshwar is renowned for. The dense forests around the resort are
          home to towering deodars, sturdy oaks, and vibrant rhododendrons, that
          paint the landscape in vivid shades during the blooming season. For
          wildlife enthusiasts, there are opportunities to spot a range of rare
          bird species, making it a great experience for birdwatchers.
          Occasional sightings of deer, foxes, and other animals further enhance
          the charm of the stay at Mukteshwar.
        </p>
        <p className="text-base md:text-lg xl:text-xl text-justify">
          The natural surroundings of Neverland not only provide a visual feast
          but also create a peaceful atmosphere that recharges one’s soul and
          body. Our resort is committed to preserving this natural beauty. We
          work hard to keep the local ecosystem balanced and ensure that the
          flora and fauna continue to thrive. Our dedication to conservation
          means you can enjoy the stunning surroundings while knowing we’re
          taking care of them for the future. Whether you love nature or just
          want a peaceful getaway, our resort offers a true connection to
          Mukteshwar's natural splendor.
        </p>
      </div>
      <div className=" md:px-10 flex flex-col gap-5 ">
      <h2 className=" font-semibold text-xl md:text-3xl xl:text-4xl justify-center items-center text-center gap-2">
          Weather Around The Year
        </h2>
        <img className="p-3  scale-90" src={img1} alt="weather in mukteshwar" />


      </div>
    </div>      
    </div>

  );
};

export default AboutUs;
